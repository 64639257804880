var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"columns"},[_c('button',{staticClass:"btn",on:{"click":_vm.addMessage}},[_vm._v("Dodaj Usługę")]),(_vm.newMessage)?_c('edition-form',{attrs:{"newMessage":_vm.newMessage,"itemId":_vm.itemId,"editingModel":_vm.editingModel,"requestUrl":'api/service',"formLayout":[
      {
        formField: 'input',
        name: 'Nazwa usługi',
        placeholder: 'Nazwa usługi',
        model: 'name',
      },
    ]}}):_vm._e(),_c('table-data',{attrs:{"headers":['Lp.', 'Nazwa'],"tableData":this.services.data,"adminCategory":'service',"searchItem":'title'},on:{"start-editing":function($event){return _vm.startEditing($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }