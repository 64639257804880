
import Vue from "vue";
import { HTTP } from "@/utils/http-common";
export default Vue.extend({
  data(): any {
    return {
      services: {
        data: [],
      },
      newMessage: "",
      itemId: "",
      editingModel: {
        name: "",
      },
    };
  },
  methods: {
    addMessage() {
      this.newMessage = "add";
    },
    startEditing(values: any) {
      this.newMessage = "edit";
      this.itemId = values.id;
      this.editingModel.name = values.name;
    },
  },
  created() {
    HTTP.get(`api/services`)
      .then((response) => {
        this.services.data = response.data.data;
      })
      .catch((e) => {
        // console.error(e);
      });
  },
});
